.nav-tabs,
.nav-pills {
  .nav-link {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }
}

.tab-content {
  &.tabcontent-border {
    border: 1px solid $border-color;
    border-top: 0px;
  }
}
// this is for custom pills
.nav-pills.custom-pills {
    .nav-link {
		font-size: 1rem;
        border-radius: 0;
        opacity: 0.7;
        &.active {
            color: $themecolor;
            opacity: 1;
            background-color: transparent;
            border-bottom: 3px solid $themecolor;
        }
    }
}
